<template>
	<div>
		<page-bar name="Featured"></page-bar>

		<v-slide-x-transition mode="out-in">
			<router-view></router-view>
		</v-slide-x-transition>
	</div>
</template>

<script>
import PageBar from '@/components/global/PageBar'

export default {
	name: 'FeaturedIndex',
	components: {PageBar},
	data: () => ({}),
}
</script>

<style scoped>

</style>
